import { formatDocument, formatPrice } from "../../utils";

const InfoLine = ({ label, value }) => {
  return (
    <div className="row" style={{ justifyContent: "space-between" }}>
      <p style={{ flex: 1 }}>{label}</p>
      <p style={{ flex: 2, textAlign: "right" }}>{value}</p>
    </div>
  );
};

const Summary = ({ invoice }) => {
  return (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: 32 }}>
        INFORMAÇÕES DA FATURA
      </h3>
      <InfoLine label="Curso" value={invoice.course_name} />
      <hr />
      <InfoLine label="Valor total" value={formatPrice(invoice.value / 100)} />
      <hr />
      <InfoLine label="CPF/CNPJ" value={formatDocument(invoice.document)} />
      <hr />
      <InfoLine label="E-mail" value={invoice.email} />
    </div>
  );
};

export default Summary;
