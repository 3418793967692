import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const script = document.createElement("script");
script.src = "https://js.iugu.com/v2";
script.async = true;

script.onload = () => {
  root.render(
    <React.StrictMode>
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100vw",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <App />
      </div>
      <ToastContainer />
    </React.StrictMode>
  );
};

document.body.appendChild(script);
