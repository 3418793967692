import { useState } from "react";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";

const AVAILABLE_INSTALLMENTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Installments = ({ centsValue, installments }) => {
  function getValue(installment) {
    const installmentValue = centsValue / installment;
    return (installmentValue / 100).toFixed(2).replace(".", ",");
  }

  return Array.from(Array(installments).keys()).map((installment) => (
    <option value={installment + 1}>
      {installment + 1}x de R$ {getValue(installment + 1)} sem juros
    </option>
  ));
};

const CardForm = ({ generateToken, maxInstallments }) => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [cardInstallments, setCardInstallments] = useState("");

  function submitForm(e) {
    e.preventDefault();

    if (!cardNumber) return toast.error("Preencha o número do cartão");
    if (!window.Iugu.utils.validateCreditCardNumber(cardNumber))
      return toast.error("Número do cartão inválido");

    if (!cardName) return toast.error("Preencha o nome do titular");
    if (cardName.split(" ").length < 2)
      return toast.error("Preencha o nome e sobrenome");

    if (!cardExpiration) return toast.error("Preencha a data de validade");
    if (!window.Iugu.utils.validateExpirationString(cardExpiration))
      if (!cardCvv) return toast.error("Preencha o CVV");
    if (cardCvv.length !== 3) return toast.error("CVV inválido");

    if (!AVAILABLE_INSTALLMENTS.includes(Number(cardInstallments)))
      return toast.error("Selecione a quantidade de parcelas");

    const [_cardName, ...cardSurname] = cardName.split(" ");
    const [cardMonth, cardYear] = cardExpiration.split("/");
    const data = {
      cardNumber,
      cardName: _cardName,
      cardSurname: cardSurname.join(" "),
      cardMonth,
      cardYear,
      cardCvv,
      cardInstallments,
    };

    generateToken(data);
  }

  return (
    <div>
      <form onSubmit={submitForm} style={{ gap: 8 }}>
        <div className="column">
          <div className="row">
            <ReactInputMask
              mask={"9999 9999 9999 9999"}
              type="text"
              placeholder="Número do cartão"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          <div className="row">
            <input
              type="text"
              placeholder="Nome do Titular"
              value={cardName}
              onChange={(e) => setCardName(e.target.value)}
            />
          </div>
          <div className="row">
            <ReactInputMask
              mask={"99/9999"}
              type="text"
              placeholder="Data de validade"
              value={cardExpiration}
              onChange={(e) => setCardExpiration(e.target.value)}
            />
            <input
              maxLength={3}
              type="text"
              placeholder="CVV"
              value={cardCvv}
              onChange={(e) => setCardCvv(e.target.value)}
            />
          </div>
          <div className="row">
            <select
              onChange={(e) => setCardInstallments(e.target.value)}
              style={{ flex: 1 }}
            >
              <option disabled selected>
                Selecione a quantidade de parcelas
              </option>

              {/* <option value="1">1x de R$ 100,00 sem juros</option>
              <option value="2">2x de R$ 50,00 sem juros</option>
              <option value="3">3x de R$ 33,33 sem juros</option> */}
              <Installments
                centsValue={242900}
                installments={maxInstallments}
              />
            </select>
          </div>
          <div className="row justify-center">
            <button className="submit" type="submit">
              Pagar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CardForm;
