import { paymetMethods } from "../constants";

function getPayableWith(payable_with) {
  if (Array.isArray(payable_with)) return payable_with;
  if (payable_with === "all") return Object.values(paymetMethods);

  return [payable_with];
}

function formatDocument(document) {
  if (document.length === 11) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return document.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
}

function formatPrice(price) {
  return price.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export { getPayableWith, formatDocument, formatPrice };
