import axios from "axios";

const iugu_api = axios.create({
  baseURL: "https://api.iugu.com/v1/",
});

const carreira_api = axios.create({
  // baseURL: "http://localhost:3000",
  baseURL: "https://goldfish-app-efsb9.ondigitalocean.app",
});

export { iugu_api, carreira_api };
