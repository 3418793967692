import { toast } from "react-toastify";
import "./App.css";
import CardForm from "./components/CardForm";
import { useEffect, useState } from "react";
import { carreira_api, iugu_api } from "./api";
import TabSelector from "./components/TabSelector";
import { getPayableWith } from "./utils";
import Summary from "./components/Summary";

function App() {
  const secureId = window.location.pathname.split("/")[1];
  // DEV ONLY
  // const secureId = "2645547d-a3cd-4de6-9570-35b5ebc09768-4663";
  // const secureId = "c6d31750-87d0-4d28-b60c-e1bc2b7a91e9-8bcb";

  const [invoice, setInvoice] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("credit_card");
  const [invoiceUrl, setInvoiceUrl] = useState("");

  window.Iugu.setAccountID(process.env.REACT_APP_ACCOUNT_ID);
  window.Iugu.setTestMode(true);

  async function generateToken(data) {
    const cc = window.Iugu.CreditCard(
      data.cardNumber,
      data.cardMonth,
      data.cardYear,
      data.cardName,
      data.cardSurname,
      data.cardCvv
    );

    window.Iugu.createPaymentToken(cc, async (response) => {
      if (response.errors) return toast.error("Erro ao salvar cartão.");

      if (response.id)
        await carreira_api
          .post("/charge", {
            method: "credit_card",
            token: response.id,
            invoice_id: invoice.id,
            months: data.cardInstallments,
          })
          .then(() => toast.success("Cobrança gerada com sucesso."))
          .catch((error) => {
            toast.error("Erro ao pagar com cartão.");
          });
    });
  }

  async function generateInvoice() {
    await carreira_api
      .post("/charge", {
        method: "bank_slip",
        invoice_id: invoice.id,
      })
      .then((data) => {
        if (!data.data.url) return toast.error("Erro ao gerar boleto.");

        toast.success("Boleto gerado com sucesso.");
        setInvoiceUrl(data.data.url);
      })
      .catch((error) => {
        console.log({ error });
        toast.error("Erro ao gerar boleto.");
      });
  }

  useEffect(() => {
    async function fetchInvoice() {
      await carreira_api(`/orders/secure/${secureId}`)
        .then((res) => {
          const _invoice = res.data.data;
          setInvoice(_invoice);
          const teste = getPayableWith(_invoice.payable_with)[0];
          setPaymentMethod(teste);
        })
        .catch((error) => toast.error("Erro ao carregar fatura."));
    }

    fetchInvoice();
  }, []);

  if (!secureId)
    return (
      <div className="column" style={{ textAlign: "center" }}>
        <h1 style={{ color: "#fff", fontWeight: "bold" }}>
          Fatura não encontrada.
        </h1>
        <p style={{ color: "#fff" }}>
          A fatura pode levar alguns minutos para estar disponível.
        </p>
      </div>
    );

  if (!invoice)
    return <h1 style={{ color: "#fff", fontWeight: "bold" }}>Carregando...</h1>;

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "100%",
        maxWidth: 900,
        backgroundColor: "#fff",
        padding: 16,
        borderRadius: 8,
      }}
    >
      <div className="row">
        <img
          style={{ margin: "8px 32px" }}
          width={130}
          src="https://c3f603.p3cdn1.secureserver.net/wp-content/uploads/2023/10/logo_carreira_muller2.png"
          alt="logo"
        />
      </div>
      <div className="row" style={{ paddingLeft: 32, paddingRight: 32 }}>
        <hr />
      </div>
      <div
        className="row"
        style={{
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "8px 32px",
        }}
      >
        <div
          className="column container"
          style={{
            width: "50%",
            alignItems: "center",
            height: 400,
          }}
        >
          <div
            className="row"
            style={{
              justifyContent: "center",
            }}
          >
            <TabSelector
              selected={paymentMethod}
              setSelected={setPaymentMethod}
              payableWith={invoice?.payable_with}
            />
          </div>
          <hr style={{ backgroundColor: "red" }} />
          <div className="row">
            {paymentMethod === "credit_card" && (
              <CardForm
                generateToken={generateToken}
                maxInstallments={invoice.installments}
              />
            )}
            {paymentMethod === "pix" && (
              <img width={250} src={invoice.pix.qrcode} alt="QR_CODE PIX" />
            )}
            {paymentMethod === "bank_slip" && (
              <div
                className="column"
                style={{ height: 250, justifyContent: "center" }}
              >
                {invoiceUrl ? (
                  <button
                    className="light"
                    onClick={() => window.open(invoiceUrl, "_blank")}
                  >
                    Acessar boleto
                  </button>
                ) : (
                  <button className="light" onClick={() => generateInvoice()}>
                    Gerar boleto
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="column container">
          <Summary invoice={invoice} />
        </div>
      </div>
    </div>
  );
}

export default App;
