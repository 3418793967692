const TabSelector = ({ selected, setSelected, payableWith }) => {
  console.log({ selected });
  return (
    <div className="row" style={{ justifyContent: "center", margin: 16 }}>
      <button
        disabled={
          payableWith !== "all" && !payableWith?.includes("credit_card")
        }
        onClick={() => setSelected("credit_card")}
      >
        Cartão
      </button>
      <button
        disabled={payableWith !== "all" && !payableWith?.includes("pix")}
        onClick={() => setSelected("pix")}
      >
        Pix
      </button>
      <button
        disabled={payableWith !== "all" && !payableWith?.includes("bank_slip")}
        onClick={() => setSelected("bank_slip")}
      >
        Boleto
      </button>
    </div>
  );
};

export default TabSelector;
